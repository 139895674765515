export const  StudentData = [
    {
    "ENROLLMENTNO": "102101003",
    "STUDENTNAME": "ARYAN PRAKASH",
    "BRANCHCODE": "CHE",
    "SUBSECTIONCODE": "3CH1",
    "SEMESTER": "5",
    "SUBJECTCODE": "UTD002",
    "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
    "STCELLNO": "8318059530",
    "STTIETEMAILID": "aprakash_be21@thapar.edu",
    "EXAMCODE": "2324ODDSEM",
    "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
    },
    {
    "ENROLLMENTNO": "102101006",
    "STUDENTNAME": "PALAK",
    "BRANCHCODE": "CHE",
    "SUBSECTIONCODE": "3CH1",
    "SEMESTER": "5",
    "SUBJECTCODE": "UTD002",
    "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
    "STCELLNO": "9855277557",
    "STTIETEMAILID": "pkaura_be21@thapar.edu",
    "EXAMCODE": "2324ODDSEM",
    "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
    },
    {
    "ENROLLMENTNO": "102101008",
    "STUDENTNAME": "VASU GUPTA",
    "BRANCHCODE": "CHE",
    "SUBSECTIONCODE": "3CH1",
    "SEMESTER": "5",
    "SUBJECTCODE": "UTD002",
    "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
    "STCELLNO": "9811247446",
    "STTIETEMAILID": "vgupta4_be21@thapar.edu",
    "EXAMCODE": "2324ODDSEM",
    "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
    },
    {
    "ENROLLMENTNO": "102101015",
    "STUDENTNAME": "BHAVIK JAIN",
    "BRANCHCODE": "CHE",
    "SUBSECTIONCODE": "3CH1",
    "SEMESTER": "5",
    "SUBJECTCODE": "UTD002",
    "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
    "STCELLNO": "8968400077",
    "STTIETEMAILID": "bjain_be21@thapar.edu",
    "EXAMCODE": "2324ODDSEM",
    "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
    },
    {
    "ENROLLMENTNO": "102101019",
    "STUDENTNAME": "LAVISHA GOYAL",
    "BRANCHCODE": "CHE",
    "SUBSECTIONCODE": "3CH2",
    "SEMESTER": "5",
    "SUBJECTCODE": "UTD002",
    "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
    "STCELLNO": "7087393977",
    "STTIETEMAILID": "lgoyal_be21@thapar.edu",
    "EXAMCODE": "2324ODDSEM",
    "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
    },
    {
    "ENROLLMENTNO": "102101026",
    "STUDENTNAME": "JHANVI RANA",
    "BRANCHCODE": "CHE",
    "SUBSECTIONCODE": "3CH2",
    "SEMESTER": "5",
    "SUBJECTCODE": "UTD002",
    "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
    "STCELLNO": "7300886975",
    "STTIETEMAILID": "jrana_be21@thapar.edu",
    "EXAMCODE": "2324ODDSEM",
    "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
    },
    {
    "ENROLLMENTNO": "102101031",
    "STUDENTNAME": "RAHUL RATHI",
    "BRANCHCODE": "CHE",
    "SUBSECTIONCODE": "3CH2",
    "SEMESTER": "5",
    "SUBJECTCODE": "UTD002",
    "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
    "STCELLNO": "8290357038",
    "STTIETEMAILID": "rrathi_be21@thapar.edu",
    "EXAMCODE": "2324ODDSEM",
    "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
    },
    {
    "ENROLLMENTNO": "102101042",
    "STUDENTNAME": "RISHITA",
    "BRANCHCODE": "CHE",
    "SUBSECTIONCODE": "3CH2",
    "SEMESTER": "5",
    "SUBJECTCODE": "UTD002",
    "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
    "STCELLNO": "6283761081",
    "STTIETEMAILID": "rmeet_be21@thapar.edu",
    "EXAMCODE": "2324ODDSEM",
    "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
    },
    {
        "ENROLLMENTNO": "102103015",
        "STUDENTNAME": "AWANTIKA AWASTHI",
        "BRANCHCODE": "COE",
        "SUBSECTIONCODE": "3CO1",
        "SEMESTER": "5",
        "SUBJECTCODE": "UTD002",
        "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
        "STCELLNO": "8874274073",
        "STTIETEMAILID": "aawasthi_be21@thapar.edu",
        "EXAMCODE": "2324ODDSEM",
        "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
        },
        {
        "ENROLLMENTNO": "102103019",
        "STUDENTNAME": "SUVIDHA SRIVASTAVA",
        "BRANCHCODE": "COE",
        "SUBSECTIONCODE": "3CO1",
        "SEMESTER": "5",
        "SUBJECTCODE": "UTD002",
        "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
        "STCELLNO": "9315448811",
        "STTIETEMAILID": "ssrivastava1_be21@thapar.edu",
        "EXAMCODE": "2324ODDSEM",
        "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
        },
        {
        "ENROLLMENTNO": "102103022",
        "STUDENTNAME": "YOGESH RATHEE",
        "BRANCHCODE": "COE",
        "SUBSECTIONCODE": "3CO1",
        "SEMESTER": "5",
        "SUBJECTCODE": "UTD002",
        "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
        "STCELLNO": "9996791632",
        "STTIETEMAILID": "yrathee_be21@thapar.edu",
        "EXAMCODE": "2324ODDSEM",
        "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
        },
        {
        "ENROLLMENTNO": "102103023",
        "STUDENTNAME": "SIDHANBIR SINGH SACHDEVA",
        "BRANCHCODE": "COE",
        "SUBSECTIONCODE": "3CO1",
        "SEMESTER": "5",
        "SUBJECTCODE": "UTD002",
        "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
        "STCELLNO": "9888410918",
        "STTIETEMAILID": "ssachdeva1_be21@thapar.edu",
        "EXAMCODE": "2324ODDSEM",
        "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
        },
        {
        "ENROLLMENTNO": "102103024",
        "STUDENTNAME": "JAGVEER SINGH",
        "BRANCHCODE": "COE",
        "SUBSECTIONCODE": "3CO1",
        "SEMESTER": "5",
        "SUBJECTCODE": "UTD002",
        "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
        "STCELLNO": "8288805625",
        "STTIETEMAILID": "jsingh20_be21@thapar.edu",
        "EXAMCODE": "2324ODDSEM",
        "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
        },
        {
        "ENROLLMENTNO": "102103026",
        "STUDENTNAME": "JASVEEN KAUR KAINTH",
        "BRANCHCODE": "COE",
        "SUBSECTIONCODE": "3CO1",
        "SEMESTER": "5",
        "SUBJECTCODE": "UTD002",
        "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
        "STCELLNO": "9326258128",
        "STTIETEMAILID": "jkainth_be21@thapar.edu",
        "EXAMCODE": "2324ODDSEM",
        "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
        },
        {
        "ENROLLMENTNO": "102103028",
        "STUDENTNAME": "DEVANSH GUPTA",
        "BRANCHCODE": "COE",
        "SUBSECTIONCODE": "3CO1",
        "SEMESTER": "5",
        "SUBJECTCODE": "UTD002",
        "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
        "STCELLNO": "9466797975",
        "STTIETEMAILID": "dgupta1_be21@thapar.edu",
        "EXAMCODE": "2324ODDSEM",
        "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
        },
        {
        "ENROLLMENTNO": "102103270",
        "STUDENTNAME": "RISHI SHARMA",
        "BRANCHCODE": "COE",
        "SUBSECTIONCODE": "3CO1",
        "SEMESTER": "5",
        "SUBJECTCODE": "UTD002",
        "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
        "STCELLNO": "7009600611",
        "STTIETEMAILID": "rsharma2_be21@thapar.edu",
        "EXAMCODE": "2324ODDSEM",
        "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
        },
        {
        "ENROLLMENTNO": "102103276",
        "STUDENTNAME": "MOYUKH BISWAS",
        "BRANCHCODE": "COE",
        "SUBSECTIONCODE": "3CO1",
        "SEMESTER": "5",
        "SUBJECTCODE": "UTD002",
        "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
        "STCELLNO": "8789465509",
        "STTIETEMAILID": "mbiswas_be21@thapar.edu",
        "EXAMCODE": "2324ODDSEM",
        "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
        },
        {
        "ENROLLMENTNO": "102283028",
        "STUDENTNAME": "SAMRIDHI SAHU",
        "BRANCHCODE": "COE",
        "SUBSECTIONCODE": "3CO1",
        "SEMESTER": "5",
        "SUBJECTCODE": "UTD002",
        "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
        "STCELLNO": "8130039959",
        "STTIETEMAILID": "ssahu50_be22@thapar.edu",
        "EXAMCODE": "2324ODDSEM",
        "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
        },
        {
        "ENROLLMENTNO": "102103267",
        "STUDENTNAME": "PULKIT ARORA",
        "BRANCHCODE": "COE",
        "SUBSECTIONCODE": "3CO10",
        "SEMESTER": "5",
        "SUBJECTCODE": "UTD002",
        "SUBJECT": "EMPLOYABILITY DEVELOPMENT SKILLS",
        "STCELLNO": "8847412522",
        "STTIETEMAILID": "parora2_be21@thapar.edu",
        "EXAMCODE": "2324ODDSEM",
        "EMPLOYEENAME": "SANMEETINDER S.SIDHU"
        },
]